export const useSidebar = () => {
  const sidebar = useState<any>('sidebar', () => false)
  const setSidebar = (t: boolean) => {
    sidebar.value = t || false
  }

  return {
    sidebar,
    setSidebar
  }
}
